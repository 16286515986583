import React from 'react';
import 'react-tippy/dist/tippy.css';

const strokeColors = ['#3497B4', '#1773A3', '#0A4D86', '#001E63'];

export default function BarOverlay({ percentage, score, name }) {
  const fill = (percentage - 1) * -1;

  const scoreClass = score.length > 5 ? 'score--sat' : 'score--act';
  const strokeColor = strokeColors[Math.ceil(strokeColors.length * percentage) - 1];

  return (
    <div
      className="bar-overlay"
    >
      <div className="donut">
        <svg className="donut-chart__svg" width="100%" height="100%" viewBox="0 0 50 50">
          <circle
            className="donut-chart__segment"
            cx="25"
            cy="25"
            r="15.91549430918954"
            fill="transparent"
            strokeWidth="12"
            stroke="#DDD6C8"
          />
          <circle
            className="donut-chart__segment"
            cx="25"
            cy="25"
            r="15.91549430918954"
            fill="transparent"
            strokeWidth="12"
            stroke={strokeColor}
            strokeDasharray={`${percentage * 100} ${fill * 100}`}
            strokeDashoffset="26"
          />
        </svg>
      </div>
      <p className={`score ${scoreClass}`}>{score}</p>
      <p className="name">{name}</p>
    </div>
  );
}
