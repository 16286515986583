import * as React from 'react';
import PropTypes from 'prop-types';
import { withSettings } from 'atomic-fuel/libs/components/settings';
import Dashboard from './dashboard/dashboard';


function Home(props) {
  const [errors, setErrors] = React.useState([]);

  const addError = (message) => {
    setErrors([...errors, message]);
  };

  return (
    <>
      {errors.length > 0 && (
        <div className="error">
          <div className="icon">
            <i className="material-icons">error</i>
          </div>
          <ul>
            {errors.map((e) => <li>{e}</li>)}
          </ul>
        </div>
      )}
      <div className="main row">

        <div className="column u-6-of-12">
          <Dashboard
            accountId={props.settings.custom_canvas_account_id}
            assessmentType="test"
            title="Top 5 Most Frequently Taken Tests"
            axisName="Tests Taken"
            addError={addError}
          />
        </div>
        <div className="column u-6-of-12">
          <Dashboard
            accountId={props.settings.custom_canvas_account_id}
            assessmentType="quiz"
            title="Top 5 Most Frequently Taken Quizzes"
            axisName="Quizzes Taken"
            addError={addError}
          />
        </div>
      </div>
    </>
  );
}

Home.propTypes = {
  settings: PropTypes.shape({
    canvas_auth_required: PropTypes.bool,
    lti_message_type: PropTypes.string,
    canvas_url: PropTypes.string,
  }).isRequired,
};


export default withSettings(Home);
