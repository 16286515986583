import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BarChart from './bar_chart';
import BarOverlay from './bar_overlay';
import DotLoader from './dot_loader';

const DateRange = {
  Last7Days: 7,
  Last24Hours: 1,
  Last30Days: 30,
};

const FILLER = [1, 2, 3, 4, 5].map((i) => (
  { id: `filler${i}`, resourceTitle: '', gradeScaleAverage: { displayValue: '', minScore: 0 } }
));


export default function Dashboard({
  accountId, assessmentType, title, axisName, addError
}) {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState(DateRange.Last7Days);


  useEffect(() => {
    if (!loading) { setLoading(true); }
    fetch(`api/atomic_assessments?${new URLSearchParams({
      account_id: accountId,
      assessment_type: assessmentType,
      days_ago: dateRange,
    })}`)
      .then((res) => res.json())
      .then((res) => {
        const { data, error } = res;
        if (error) {
          addError(error);
        } else {
          const newAssessments = data.sort((a, b) => b.attemptsCount - a.attemptsCount)
            .filter((a) => a.attemptsCount !== 0);

          setAssessments([...newAssessments, ...FILLER.slice(0, 5 - newAssessments.length)]);
        }

        setLoading(false);
      }).catch(() => {
        addError('Something went wrong, please try again later');
        setLoading(false);
      });
  }, [accountId, assessmentType, dateRange]);

  const renderOverlay = () => {
    const filtered = assessments.filter((a) => !String(a.id).startsWith('filler'));
    if (filtered.length === 0) {
      return <p className="aj-text">No assessments have been taken in the selected time frame</p>;
    }

    return filtered.map((a) => (
      <BarOverlay
        percentage={a.gradeScaleAverage.minScore}
        score={a.gradeScaleAverage.displayValue}
        name={a.resourceTitle}
        key={a.id}
      />
    ));
  };


  return (
    <section className="dashboard">

      <div className="dashboard__main">

        <div className="bar-graph__container">
          <div className="bar-graph__header">
            <h2 className="title">{title}</h2>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="date-range-picker" className="aj-hidden">
              Date Range
            </label>
            <select
              name="date-range"
              id="date-range-picker"
              className="date-range"
              value={dateRange}
              onChange={(e) => setDateRange(parseInt(e.target.value, 10))}
            >
              <option value={DateRange.Last24Hours}>Last 24 Hours</option>
              <option value={DateRange.Last7Days}> Last 7 Days</option>
              <option value={DateRange.Last30Days}>Last 30 Days</option>
            </select>
          </div>

          {loading ? (
            <div className="bar-graph">
              <DotLoader />
            </div>
          ) : (
            <div className="bar-graph">
              <BarChart
                assessments={assessments}
                title={axisName}
              />
              <div className="bar-overlays">
                {renderOverlay()}
              </div>
            </div>

          )}
        </div>

      </div>
    </section>
  );
}

Dashboard.propTypes = {
  accountId: PropTypes.string,
  assessmentType: PropTypes.string,
  title: PropTypes.string,
  axisName: PropTypes.string,
  addError: PropTypes.func,
};
