import React from 'react';

export default class DotLoader extends React.Component {
  render() {
    return (
      <div className="dot-loader">
        <div>
          <span />
          <span />
          <span />
        </div>
        <p>Loading</p>
      </div>
    );
  }
}
