import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
);

export default function BarChart({ assessments, title }) {
  const data = assessments.map((a) => a.attemptsCount);
  const labels = assessments.map((a) => a.resourceTitle);

  return <Bar
    options={{
      indexAxis: 'y',
      maintainAspectRatio: false,
      scales: {
        xAxis: {
          beginAtZero: true,
          ticks: {
            precision: 0
          }
        },
        yAxis: {
          ticks: { display: false },
        }
      },
      plugins: {
        title: {
          display: true,
          text: title,
          position: 'bottom',
          fontStyle: 'normal',
          fontSize: 16,
          padding: 0,
        }
      },
    }}
    data={{
      labels,
      datasets: [
        {
          backgroundColor: 'rgba(251,181,37,.2)',
          borderColor: 'rgba(251,181,37,1)',
          borderWidth: 1,
          hoverBorderWidth: 2,
          highlightFill: 'rgba(220,220,220,0.75)',
          barPercentage: 0.8,
          barThickness: 24,
          data,
        }
      ]
    }}
  />;
}
